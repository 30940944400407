import React from 'react'
import { graphql } from 'gatsby'
import { responsiveImage } from '../../utils/helpers.js'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import AltList from '../../components/altList'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const hero = responsiveImage(post.frontmatter.image.childImageSharp.fluid.srcSetWebp)
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + hero}
        />

        <Hero
          class="intro-65"
          image={hero}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>

                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-light-blue">
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <AltList
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      description={sections.description}
                      image={sections.image.childImageSharp.fluid}
                      imageAltText={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium text-very-dark-blue"
                      bullets={sections.bullets}
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        ) : (
          ''
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        strapline
        ceo
        image {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        sectiontitle
        section {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
          bullets {
            text
          }
        }
      }
      html
    }
  }
`
